import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Dictionary } from '@resparke/models/global.model';
import { isLadenArray } from '@resparke/utils/array';
import { map, Observable, of } from 'rxjs';

// export type DialogParams = {
//   panelClass: string;
//   backdropClass: string;
//   autoFocus?: boolean;
//   closeOnNavigation?: boolean;
//   data?: any;
// }
export class DialogClass {



  constructor(
    public dialog: MatDialog,
    public contentService: any,
    public appState: any,
  ) {
  }

  /**
   * @description Generic dialog opener with support for Contenful content
   * @param ref Reference of Dialog Component
   * @param content String of contentfull content collection items.
   * @param dialogConfig Map of properties like `panelClass` and `backdropClass` and possible data you want to pass on to the dialog component
   * @returns Returns short lives observable value of MatDialogRef
   */
  showDialog<T>(ref: any, content?: string[], dialogConfig?: MatDialogConfig): Observable<MatDialogRef<T, any>> {
    const previouslyFocused = document.activeElement as HTMLElement | null;
    if (previouslyFocused?.blur) {
      previouslyFocused.blur();
    }

    let loadContent$: Observable<Dictionary<string>> = (isLadenArray(content) && this.contentService) ?
      // Using content service here is not nice because it is part of the consumers `this` object. This can lead to errors if this function is not available
      this.contentService.getContent(content)
        .pipe(
          map(() => {
            let data: Dictionary<string> = {};
            const keys = extractCollectionKeys(content);
            for (const key of keys) {
              const col = this.appState.get('contentItemCollection')[key];
              if (!col) { continue }
              // if we have more than 1 groups of content, we need to include groupname (key) to prevent overwrites
              if (keys.length > 1) {
                data[key] = col;
              } else {
                data = { ...col };
              }
            }
            return data;
          })
        )
      : of({});

    return loadContent$.pipe(
      map(data => {
        const dialogRef = this.dialog.open<T>(ref, {
          ...dialogConfig,
          data: {
            ...(dialogConfig?.data || {}),
            ...data
          },
          autoFocus: true,
          restoreFocus: true,
          width: dialogConfig.width ? dialogConfig.width : 'auto',
          maxWidth: dialogConfig.maxWidth ? dialogConfig.maxWidth : 'auto',
          closeOnNavigation: dialogConfig.closeOnNavigation ? dialogConfig.closeOnNavigation : true,
        });
        dialogRef.afterClosed().subscribe(() => {
          if (previouslyFocused && previouslyFocused.focus) {
            previouslyFocused.focus();
          }
        })
        return dialogRef;
      })
    )

    function extractCollectionKeys(content: string[]): string[] {
      return Array.from(new Set(content.map(val => val.split(':')[0])));
    }
  }
}
